import server from '@/services/server';

import type { OptionType, SelectProps } from '../types';

const PAGINATION_PARAM_NAME = 'page';

export const loadOptions =
  (
    formatValue: SelectProps['formatValue'],
    onChange: (value: any) => void,
    searchParamName: string,
    searchParams: URLSearchParams,
    value: any,
    fieldName: string,
    options?: string[] | OptionType[],
    url?: string,
    mapper?: SelectProps['mapper'],
    mapperValueAccessor?: SelectProps['mapperValueAccessor'],
    sorter?: SelectProps['sorter'],
    version?: number,
    showAllOption?: boolean,
    showAllOptionLabel = 'All',
    includeParams?: string[],
    localSearch?: boolean
  ) =>
  async (search: string, _: unknown, additional?: { page: number }) => {
    // Handle case when options are provided
    if (!url) {
      return {
        hasMore: false,
        options: formatValue
          ? formatValue(options, fieldName)
          : (options?.map(mapper!) ?? [])
      };
    }

    // Get parameters
    const page = additional?.page || 1;
    const queryParams = new URLSearchParams([
      [PAGINATION_PARAM_NAME, page.toString()],
      ...(search && !localSearch ? [[searchParamName, search]] : []),
      ...Array.from(searchParams).filter(([key]) =>
        includeParams
          ? includeParams.includes(key)
          : ![PAGINATION_PARAM_NAME, searchParamName, 'term'].includes(key)
      )
    ]);

    try {
      // Get data for select
      const data = await server.get({
        path: url,
        queryParams,
        version
      });

      let formattedData: OptionType[] = [];

      // Format data
      if (formatValue) {
        // Old data formatter
        formattedData = formatValue(data, fieldName);
      } else if (mapper && mapperValueAccessor) {
        // New data formatter
        const dataToFormat = sorter
          ? { [mapperValueAccessor]: data[mapperValueAccessor].sort(sorter) }
          : data;

        formattedData = dataToFormat[mapperValueAccessor].map((item: any) => {
          const mappedItem =
            typeof item === 'string' || typeof item === 'number'
              ? mapper(item)
              : mapper({ ...item });
          return { ...item, ...mappedItem };
        });
      }

      // Add "All" option
      if (showAllOption) {
        formattedData.unshift({ label: showAllOptionLabel, value: '_ALL' });
      }

      // If no label provided this will take label from data collected from API
      const defaultOption = Array.isArray(value)
        ? value
            .filter(option => option !== null)
            .map(current => {
              const found = formattedData.find(
                option =>
                  option.value?.toString() === current?.value?.toString()
              );
              const merged = found ? { ...found, ...current } : current;
              return { ...merged, label: merged?.label || merged?.value };
            })
        : (() => {
            const found = formattedData.find(
              option => option.value?.toString() === value?.value?.toString()
            );
            const merged = found ? { ...found, ...value } : value;
            return { ...merged, label: merged?.label || merged?.value };
          })();

      // Set new options to update labels
      if (
        defaultOption &&
        value &&
        (Array.isArray(value) ? value.some(v => !v.label) : !value.label) &&
        !search
      ) {
        onChange(defaultOption);
      }

      return {
        hasMore: data.meta?.pagination
          ? data.meta.pagination.pages > data.meta.pagination.page
          : data.meta
            ? data.meta.pages > data.meta.page
            : false,
        options: formattedData,
        additional: { page: page + 1 }
      };
    } catch (error) {
      console.error('Failed to load options:', error);

      return {
        hasMore: false,
        options: []
      };
    }
  };
