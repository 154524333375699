import { Outlet, Route, Routes } from 'react-router-dom';

import PageContainer from '@/components/PageContainer';
import UpStatus from '@/components/UpStatus';
import routes from '@/routes';

import {
  AdsCreatorForm,
  AdsCreatorList,
  BackgroundImagesDetails,
  BudgetsForm,
  BudgetsList,
  CartRulesForm,
  CartRulesList,
  CompaniesForm,
  CompaniesList,
  CompanyGroupCostCodesForm,
  CompanyGroupCostCodesList,
  CustomDomainsForm,
  CustomDomainsList,
  CustomerSupportDetails,
  CustomerSupportList,
  DealerLogoForm,
  DealerLogoList,
  FeaturedProductsList,
  FixedShippingLocationForm,
  FixedShippingLocationList,
  GiftCertificatesForm,
  GiftCertificatesList,
  LogoManagementForm,
  LogoManagementList,
  MainColorsForm,
  MassLoadPartsForm,
  MassLoadPartsList,
  MasterPartsList,
  NonMasterPartsForm,
  NonMasterPartsList,
  NotFound,
  PasswordUsersForm,
  PasswordUsersList,
  PickingBatches,
  PickToShipList,
  PrimaryInformationDetails,
  ProductColorsForm,
  ProductColorsList,
  SalesReports,
  SliderCreator,
  SpecialComponentsEdit,
  SpecialComponentsList,
  SpecialComponentsNew,
  StoreAgreementsList,
  ValidCodesForm,
  ValidCodesList,
  VendorInventoriesList,
  WorkflowProductionList
} from './pages';

const App = () => (
  <Routes>
    <Route element={<Outlet />} path="/">
      <Route element={<PageContainer />}>
        <Route path={routes.adsCreator}>
          <Route element={<AdsCreatorList />} index />
          <Route element={<AdsCreatorForm />} path="new" />
          <Route element={<AdsCreatorForm />} path=":id/edit" />
        </Route>
        <Route path={routes.backgroundImages}>
          <Route element={<BackgroundImagesDetails />} index />
        </Route>
        <Route path={routes.budgets}>
          <Route element={<BudgetsList />} index />
          <Route element={<BudgetsForm />} path="new" />
          <Route element={<BudgetsForm />} path=":id/show" />
          <Route element={<BudgetsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.cartRules}>
          <Route element={<CartRulesList />} index />
          <Route element={<CartRulesForm />} path="new" />
          <Route element={<CartRulesForm />} path=":id/show" />
          <Route element={<CartRulesForm />} path=":id/edit" />
        </Route>
        <Route path={routes.companies}>
          <Route element={<CompaniesList />} index />
          <Route element={<CompaniesForm />} path="new" />
          <Route element={<CompaniesForm />} path=":id/edit" />
        </Route>
        <Route path={routes.customDomains}>
          <Route element={<CustomDomainsList />} index />
          <Route element={<CustomDomainsForm />} path="new" />
          <Route element={<CustomDomainsForm />} path=":id/show" />
          <Route element={<CustomDomainsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.customerSupport}>
          <Route element={<CustomerSupportList />} index />
          <Route element={<CustomerSupportDetails />} path=":id/edit" />
        </Route>
        <Route path={routes.dealerLogos}>
          <Route element={<DealerLogoList />} index />
          <Route element={<DealerLogoForm />} path=":id/show" />
        </Route>
        <Route path={routes.companyGroupCostCodes}>
          <Route element={<CompanyGroupCostCodesList />} index />
          <Route element={<CompanyGroupCostCodesForm />} path=":id/edit" />
          <Route element={<CompanyGroupCostCodesForm />} path="new" />
        </Route>
        <Route path={routes.featuredProducts}>
          <Route element={<FeaturedProductsList />} index />
        </Route>
        <Route path={routes.fixedShippingLocations}>
          <Route element={<FixedShippingLocationList />} index />
          <Route element={<FixedShippingLocationForm />} path="new" />
          <Route element={<FixedShippingLocationForm />} path=":id/edit" />
        </Route>
        <Route path={routes.giftCertificates}>
          <Route element={<GiftCertificatesList />} index />
          <Route element={<GiftCertificatesForm />} path=":id/edit" />
        </Route>
        <Route path={routes.logoManagement}>
          <Route element={<LogoManagementList />} index />
          <Route element={<LogoManagementForm />} path="new" />
          <Route element={<LogoManagementForm />} path=":id/edit" />
          <Route element={<LogoManagementForm />} path=":id/duplicate" />
        </Route>
        <Route path={routes.mainColors}>
          <Route element={<MainColorsForm />} path="new" />
          <Route element={<MainColorsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.massLoadParts}>
          <Route element={<MassLoadPartsList />} index />
          <Route element={<MassLoadPartsForm />} path="new" />
          <Route element={<MassLoadPartsForm />} path=":id/show" />
          <Route element={<MassLoadPartsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.masterParts}>
          <Route element={<MasterPartsList />} index />
        </Route>
        <Route path={routes.nonMasterPartGroups}>
          <Route element={<NonMasterPartsList />} index />
          <Route element={<NonMasterPartsForm />} path="new" />
          <Route element={<NonMasterPartsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.passwordUsers}>
          <Route element={<PasswordUsersList />} index />
          <Route element={<PasswordUsersForm />} path="new" />
          <Route element={<PasswordUsersForm />} path=":id/edit" />
        </Route>
        <Route path={routes.pickToShip}>
          <Route element={<PickToShipList />} index />
        </Route>
        <Route path={routes.primaryInformation}>
          <Route element={<PrimaryInformationDetails />} index />
        </Route>
        <Route path={routes.productColors}>
          <Route element={<ProductColorsList />} index />
          <Route element={<ProductColorsForm />} path="new" />
          <Route element={<ProductColorsForm />} path=":id/edit" />
        </Route>
        <Route path={routes.salesReports}>
          <Route element={<SalesReports />} index />
        </Route>
        <Route path={routes.sliderCreator}>
          <Route element={<SliderCreator />} index />
        </Route>
        <Route path={routes.specialComponents}>
          <Route element={<SpecialComponentsList />} index />
          <Route element={<SpecialComponentsNew />} path="new" />
          <Route element={<SpecialComponentsEdit />} path=":id/edit" />
        </Route>
        <Route path={routes.storeAgreements}>
          <Route element={<StoreAgreementsList />} index />
        </Route>
        <Route path={routes.validCodes}>
          <Route element={<ValidCodesList />} index />
          <Route element={<ValidCodesForm />} path="new" />
          <Route element={<ValidCodesForm />} path=":id/edit" />
        </Route>
        <Route path={routes.vendorInventories}>
          <Route element={<VendorInventoriesList />} index />
        </Route>
        <Route path={routes.workflowProduction}>
          <Route element={<WorkflowProductionList />} index />
        </Route>
        <Route element={<NotFound />} path="*" />
        <Route element={<NotFound />} index />
      </Route>
      <Route
        element={<PickingBatches />}
        path={`${routes.workflowProduction}/:id/preview`}
      />
    </Route>
    <Route element={<UpStatus />} path="up" />
  </Routes>
);

export default App;
